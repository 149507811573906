<template>
  <v-form class="fill-height" @submit.prevent>
    <gw-form-observer
      :loading="isLoading"
      hide-save-action
      hide-top-actions
      @cancel="onClose"
    >
      <template #bottom-actions="{ invalid }">
        <v-avatar v-if="invalid" class="mr-4" color="warning" size="32">
          <v-icon dark small>mdi-alert-outline</v-icon>
        </v-avatar>

        <v-btn
          v-if="inView"
          :small="isMobile"
          :to="updateUrl"
          color="primary"
          depressed
        >
          {{ $t("edit") }}
        </v-btn>

        <v-btn
          v-if="inView"
          :disabled="invalid"
          :small="isMobile"
          color="primary"
          depressed
          @click="onSign()"
        >
          {{ $t("sign") }}
        </v-btn>
      </template>

      <settings
        v-model="obModel"
        :hide-movement-type-select="hideMovementTypeSelect"
        :read-only="signed"
        v-bind="$attrs"
      />
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesMixin from "@/modules/invoices/mixins/InvoicesMixin";
import { InvoiceModule } from "@/store/invoice";
import Settings from "@/modules/invoices/components/tabs/Settings.vue";
import ReferencesSyncDialog from "@/modules/invoices/components/references/ReferencesSyncDialog.vue";
import { delay } from "lodash";

@Component({
  components: { ReferencesSyncDialog, Settings },
})
export default class InvoiceVew extends Mixins(InvoicesMixin) {
  get obInvoice() {
    return InvoiceModule.invoice;
  }

  get signed(): boolean {
    return InvoiceModule.signed;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  get updateUrl() {
    return { name: "invoices.update", params: { id: this.obInvoice.id } };
  }

  async onClose() {
    let sRouteName = this.signed && !this.inView ? "emitted" : "saved";

    if (this.$route.name) {
      switch (this.$route.name) {
        case "invoices.receipt.update":
        case "invoices.receipt.create":
          sRouteName = `receipt.${sRouteName}`;
          break;

        case "invoices.debt.update":
        case "invoices.debt.create":
          sRouteName = `debt.${sRouteName}`;
          break;
      }
    }

    return await this.$router.push({ name: `invoices.${sRouteName}` });
  }

  onSign() {
    this.obInvoice.set("sign_only", true);
    this.$emit("input", this.obInvoice);
    this.onsaveDelayed();
  }

  onsaveDelayed() {
    delay(this.save, 300);
  }
}
</script>
